<header>
  <div class="container">
    <div class="row pt-5">
      <div class="col-12 col-xl-6 d-none d-md-block" id="regMode">
        <ngb-carousel [showNavigationIndicators]="false">
          <ng-template ngbSlide class="carousel-item">
            <div class="row d-flex flex-row justify-content-center align-items-center">
              <div class="col-12">
                <div class="h4">Satu Akses Untuk Semua Toko Jualan Mu!</div>
              </div>
              <div class="col-12 col-md-6">
                <img src="assets/products/op1.svg" alt="Register">
              </div>
              <div class="col-12 col-md-6">
                <p><em>Platform</em> yang terhubung dengan beberapa
                  marketplace untuk mengatur dan memproses
                  seluruh order Omnichannel Anda, inventaris
                  produk secara real time, upload massal produk,
                  chat multiple marketplace dan termasuk
                  sistem POS untuk penjualan offline.
                </p>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide class="carousel-item">
            <div class="row d-flex flex-row justify-content-center align-items-center">
              <div class="col-12">
                <div class="h4">Keuntungan Menggunakan OmniPos</div>
              </div>
              <div class="col-12 col-md-6">
                <ol>
                  <li class="fs-6">Dapat memproses seluruh aktivitas orderan <em>marketplace</em> Anda dalam satu <em>platform</em>;
                  </li>
                  <li class="fs-6"><em>Real Time</em> inventaris stok di <em>marketplace</em> dan website Anda yang terhubung;</li>
                  <li class="fs-6">Terdapat sistem POS untuk jualan offline;</li>
                  <li class="fs-6">Dapat terintegrasi ke Software pembukuan seperti <em>Accurate</em> dan Jurnal;</li>
                </ol>
              </div>
              <div class="col-12 col-md-6">
                <img src="assets/products/op3.svg" alt="OmniPOS" class="w-100">
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide class="carousel-item">
            <div class="row d-flex flex-row justify-content-center align-items-center">
              <div class="col-12">
                <div class="h4">Keuntungan Menggunakan OmniPos</div>
              </div>
              <div class="col-12 col-md-6">
                <ol start="5">
                  <li class="fs-6">Chat <em>marketplace</em> Anda di satu <em>platform</em>;</li>
                  <li class="fs-6">Bisa melakukan <em>upload</em> massal dan duplikasi produk ke semua <em>marketplace</em>;</li>
                  <li class="fs-6"><em>Download</em> laporan penjualan dan data pelanggan tiap <em>marketplace</em> Anda per
                    periode/tanggal.</li>
                </ol>
              </div>
              <div class="col-12 col-md-6">
                <img src="assets/products/op3.svg" alt="OmniPOS" class="w-100">
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div class="col-12 col-xl-6">
        <form autocomplete="off" [formGroup]="regForm" (ngSubmit)="onSubmit()">
          <div class="form-group row pt-5 mb-0">
            <div class="col-12 col-md-5 offset-md-1 mb-3">
              <input type="text" name="nama_toko" formControlName="nama_toko" class="form-control" id="nama_toko"
                placeholder="Nama Toko" minlength="4">
              <div *ngIf="(submitted || reg.nama_toko.touched) && reg.nama_toko.errors" class="text-danger">
                <small *ngIf="reg.nama_toko.errors.required">Nama Toko Harus Diisi</small>
                <small *ngIf="reg.nama_toko.errors.minlength">Nama Toko Minimal 4 Karakter</small>
              </div>
            </div>
            <div class="col-12 col-md-5 mb-3">
              <input type="text" name="nama_pengguna" formControlName="nama_pengguna" class="form-control"
                id="nama_pengguna" placeholder="Username" pattern="^(?:[a-zA-Z0-9]+)?$" minlength="4">
              <div *ngIf="(submitted || reg.nama_pengguna.touched) && reg.nama_pengguna.errors" class="text-danger">
                <small *ngIf="reg.nama_pengguna.errors.required">Username Harus Diisi</small>
                <small *ngIf="reg.nama_pengguna.errors.minlength">Username Minimal 4 Karakter</small>
                <small *ngIf="reg.nama_pengguna.errors.pattern">Username Hanya Berupa Alfanumerik</small>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="col-12 col-md-5 offset-md-1 mb-3">
              <input type="email" name="email" formControlName="email" class="form-control" id="email"
                placeholder="E-mail">
              <div *ngIf="(submitted || reg.email.touched) && reg.email.errors" class="text-danger">
                <small *ngIf="reg.email.errors.required">Email Harus Diisi</small>
                <small *ngIf="reg.email.errors.pattern">Format Email Tidak Sesuai</small>
              </div>
            </div>
            <div class="col-12 col-md-5 mb-3">
              <input type="tel" name="no_telp" formControlName="no_telp" class="form-control" id="no_telp"
                placeholder="Nomor Whatsapp" pattern="^(\+62|62|0)8[1-9][0-9]{6,9}$">
              <div *ngIf="(submitted || reg.no_telp.touched) && reg.no_telp.errors" class="text-danger">
                <small *ngIf="reg.no_telp.errors.required">Nomor Whatsapp Harus Diisi</small>
                <small *ngIf="reg.no_telp.errors.pattern">Format Nomor Whatsapp Tidak Sesuai</small>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="col-12 col-md-5 offset-md-1 mb-3">
              <input [type]="viewPass ? 'text' : 'password'" name="password" formControlName="password"
                class="form-control" id="password" placeholder="Password" autocomplete="new-password">
              <div *ngIf="(submitted || reg.password.touched) && reg.password.errors" class="text-danger">
                <small *ngIf="reg.password.errors.required">Password Harus Diisi</small>
              </div>
            </div>
            <div class="col-12 col-md-5 mb-3">
              <input [type]="viewPass ? 'text' : 'password'" name="c_password" formControlName="c_password"
                class="form-control" id="c_password" placeholder="Ulangi Password" autocomplete="new-password"
                pattern="{{reg.password.value}}">
              <div *ngIf="(submitted || reg.c_password.touched) && reg.c_password.errors" class="text-danger">
                <small *ngIf="reg.c_password.errors?.required">Konfirmasi Password Harus Diisi</small>
                <small *ngIf="reg.c_password.errors?.pattern">Konfirmasi Password Harus Sama</small>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="col-12 col-md-5 offset-md-1 mb-3">
              <div class="form-check">
                <input type="checkbox" class="check-control form-check-input" id="lihat-password" [value]="viewPass"
                  (change)="viewPass = !viewPass">
                <label class="form-check-label" for="lihat-password">Lihat Password</label>
              </div>
            </div>
          </div>
          <div class="form-group checkbox-syarat pt-5 text-center">
            <div class="col-12">
              <input type="checkbox" class="form-check-input" id="tnc" formControlName="tnc">
              <label class="form-check-label" for="tnc" style="width: 250px;">Saya telah membaca, memahami dan
                menyetujui <a id="snk" (click)="viewTnC($event)">Syarat dan Ketentuan</a></label>
              <div *ngIf="(submitted || reg.tnc.touched) && reg.tnc.errors" class="text-danger">
                <small *ngIf="reg.tnc.errors.required">Kamu harus menyetujui syarat dan ketentuan yang berlaku</small>
              </div>
            </div>
          </div>
          <div class="btn-regist pt-5 text-center">
            <button type="submit" class="btn btn-custom1">Registrasi</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</header>
