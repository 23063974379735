<div class="modal-header">
  <h5 class="modal-title" id="syaratKetentuan">Syarat dan Ketentuan</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="tnc">
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-custom3" (click)="activeModal.close('Close click')">Tutup</button>
</div>
