import { Component } from '@angular/core';
import { Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'dashboard-aturtoko';
  isCollapsed = false;

  constructor(
    private router: Router
  ) {}

  register() {
    this.router.navigate(['/daftar']);
    // const modalRef = this.modalService.open(RegisterComponent, {
    //   windowClass: 'modal-xl', backdrop: 'static', keyboard: true, centered: true
    // });
    // modalRef.result.then(
    //   (res: any) => {
    //     window.open('https://store.aturtoko.id/login', '_blank');
    //   }, (err: any) => {
    //     console.log(err);
    //   }
    // )
    
  }
}
