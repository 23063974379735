import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TNCComponent } from '../tnc/tnc.component';
import { ToastService } from '../../toast.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  viewPass = false;
  regForm: FormGroup;
  submitted: boolean = false;
  hasParams: any;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private ts: ToastService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params: any) => {
        console.log(params);
        this.hasParams = params;
      }
    )
    this.regForm = this.fb.group({
      nama_toko: ['', [Validators.required, Validators.minLength(4)]],
      nama_pengguna: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$")]],
      no_telp: ['', Validators.required],
      jenis_usaha: ['Lainnya'],
      alamat_toko: ['Alamat'],
      password: ['', Validators.required],
      c_password: ['', Validators.required],
      role_id: [1, Validators.required],
      marketplace: [1, Validators.required],
      is_active: [true, Validators.required],
      pilihan_paket: ['AturToko +', Validators.required],
      tnc: [false, Validators.requiredTrue]
    })
  }

  get reg() {
    return this.regForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.regForm.invalid) {
      return;
    }
    let params = new HttpParams();
    Object.keys(this.hasParams).forEach( (item) => {
      params = params.set(item, this.hasParams[item]);
    });
    this.http.post('https://reg.aturtoko.id/api/v1/register?' + params, this.regForm.value).subscribe(
      (res: any) => {
        this.ts.show('Registrasi berhasil!', {
          classname: 'bg-success text-light',
          autohide: true,
          delay: 5000
        });
        setTimeout(() => {
          window.open('https://store.aturtoko.id/login', '_blank');
        }, 5000);
      }, (err: any) => {
        for (const key in err.error) {
          this.ts.show(`${err.error[key][0]}`, {
            classname: 'bg-danger text-light',
            autohide: true,
            delay: 5000
          });
        }
      }
    )
  }

  viewTnC(e: any) {
    e.stopPropagation();
    const modalRef = this.modalService.open(TNCComponent, {
      windowClass: 'modal-xl', backdrop: 'static', keyboard: true, centered: true
    });
  }

}
