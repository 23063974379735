<header>
  <div class="container">
    <div class="row pt-5">
      <div class="col-12 col-md-6" id="leftMain">
        <div class="h6">AturToko percaya bahwa</div>
        <div class="h4">Setiap orang dapat memiliki kesempatannya masing-masing</div>
        <p>Untuk dapat menjangkau lebih mudah, fleksibel, dan efisien untuk memiliki usahanya sendiri.</p>
      </div>
      <div class="col-12 col-md-6" id="rightMain">
        <div id="mainImg">
          <div id="bgImg" class="d-none d-sm-block"></div>
          <img src="assets/backgrounds/headline-rev.webp" alt="Kantor AturToko" width="600" height="600"
            class="d-block d-md-none">
          <img src="assets/backgrounds/headline-rev.webp" alt="Kantor AturToko" width="550" height="640"
            class="d-none d-md-block">
          <div class="blur-box" (click)="toTestimonial()">
            <h6>Apa kata {{rt.name}}, {{rt.brand}}?</h6>
            <p>{{short}}</p>
            <img [src]="'/assets/clients/' + rt.label + '.webp'" [alt]="rt.brand" class="icon-box">
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<main>
  <section class="section-1">
    <div class="container product">
      <p>
        Kami membantu kamu untuk berjualan di <em>marketplace</em>
        dengan tiga produk unggulan kami.
      </p>
      <h2>OmniPos, BuatToko, dan AturToko+</h2>
      <div class="cards text-center">
        <div class="d-flex flex-column flex-lg-row justify-content-between" style="gap: 30px;">
          <div class="card">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="card-title">
                <img src="assets/logos/omnipos.svg" alt="Logo OmniPOS" width="200" height="50">
              </div>
              <p class="card-text" style="letter-spacing: -0.5px;">
                Mengelola bisnis <em>online</em> secara mudah dalam satu <em>dashboard</em>
              </p>
              <div class="cobaProduct">
                <img src="assets/products/omnipos-card.webp" alt="Ilustrasi OmniPOS" width="500" height="500">
              </div>
              <a [routerLink]="['/omnipos']" fragment="registrasi"
                class="btn btn-custom1 px-4 py-2 primary-btn my-5 mx-auto">Coba Gratis</a>
            </div>
          </div>
          <div class="card">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="card-title">
                <img src="assets/logos/buattoko.svg" alt="Logo BuatToko" width="200" height="50">
              </div>
              <p class="card-text">
                Memperkuat <em>branding</em> secara tepat dengan menggunakan website
              </p>
              <div class="cobaProduct">
                <img src="assets/products/buattoko-card.webp" alt="Ilustrasi BuatToko" width="500" height="500">
              </div>
              <a [routerLink]="['/buattoko']" fragment="registrasi"
                class="btn btn-custom2 px-4 py-2 primary-btn my-5 mx-auto">Coba Gratis</a>
            </div>
          </div>
          <div class="card">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="card-title">
                <img src="assets/logos/atplus.svg" alt="Logo AturToko +" width="200" height="50">
              </div>
              <p class="card-text">
                Cukup fokus pada produksi kamu. Kami yang memasarkan.
              </p>
              <div class="cobaProduct">
                <img src="assets/products/aturtokoplus-card.webp" alt="Ilustrasi AturToko +" width="500" height="500">
              </div>
              <a [routerLink]="['/aturtokoplus']" fragment="registrasi"
                class="btn btn-custom3 px-5 py-2 primary-btn my-5 mx-auto">Daftar</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="h6">Kelola bisnismu lebih profesional dengan AturToko</div>
      <div class="h2">Atur Toko Online Jadi Gampang!</div>
      <div class="cardInfo">
        <div class="row">
          <div class="col-12 col-md-6">
            <video controls class="w-100" preload="metadata" poster="assets/products/cover.webp" autopictureinpicture>
              <source src="aturtoko.mp4" type="video/mp4">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          <div class="col-12 col-md-6 d-flex">
            <p class="align-self-center">AturToko akan membantu mengembangkan bisnis Kamu mulai dari digital marketing,
              budgeting iklan hingga meningkatkan kinerja sosial media kamu.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-2" id="testimonial">
    <div class="container">
      <h2 class="title">Apa Kata Rekan AturToko?</h2>
      <div class="row">
        <div class="col-12">
          <ngb-carousel *ngIf="testimonials">
            <ng-template ngbSlide *ngFor="let t of testimonials" class="carousel-item">
              <div class="row mb-3">
                <div class="col-12 col-md-2 client-logo">
                  <img [src]="'assets/clients/' + t.label + '.webp'" [alt]="t.brand" class="client-logo">
                </div>
                <div class="col-12 col-md-10 client-name">
                  <span>{{t.name}}, {{t.brand}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="client-testimonial">
                    {{t.testimonial}}
                  </p>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>
  </section>

  <section class="section-4">
    <div class="liputan">
      <div class="container">
        <h2>Liputan AturToko</h2>
        <ngx-slick-carousel class="partner-carousel" #slickModal="slick-carousel" [config]="slideConfig"
          (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let l of liputan" class="slide">
            <a [href]="l.url" target="_blank" rel="noopener noreferrer nofollow"><img [src]="l.img" [alt]="l.url"
                width="12.5rem" height="12.5rem" class="slick-img"></a>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </section>

  <section class="section-3">
    <div class="partner">
      <div class="container">
        <h2>Partner Kami</h2>
        <ngx-slick-carousel class="partner-carousel" #slickModal="slick-carousel" [config]="slideConfig"
          (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let slide of slides" class="slide">
            <img [src]="slide.img" [alt]="slide.label" class="slick-img" width="12.5rem" height="4.6875rem">
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </section>
</main>
