import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from './products/register/register.component';

const routes: Routes = [
  { path: 'tentang', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'harga', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'omnipos', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
  { path: 'buattoko', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
  { path: 'aturtokoplus', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
  { path: 'daftar', component: RegisterComponent },
  { path: '', component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
