export const clients: any = {
  clients: [
    {
      label: 'aelin',
      name: 'Adjie',
      brand: 'Aelin Skincare',
      testimonial: 'Sebelumnya penjualan kami agak kurang signifikan, namun semenjak kami mengunakan service AturToko, penjualan produk kami lebih meningkat.'
    },
    {
      label: 'arra-beauty',
      name: 'Puspa',
      brand: 'ARRA Beauty',
      testimonial: 'AturToko bisa mengelola Shopee Ads kami dengan baik. Selain itu juga memberikan strategi marketing yang membantu performa toko kami di Shopee menjadi lebih baik.'
    },
    {
      label: 'draft-8-leather-goods',
      name: 'Romano',
      brand: 'Draft-8 leather goods',
      testimonial: 'Very good service. Very professional. I recommended them to anyone who want to grow their sales and social media.'
    },
    {
      label: 'kidtink',
      name: 'Putra',
      brand: 'Kidtink',
      testimonial: 'Online menjadi salah satu strategi pengembangan bisnis kami, kami mencari partner yang bisa mengakomodir pengembangan ini. Aturtoko membantu kami dari branding dan selling di divisi online kami. Terima kasih Aturtoko.'
    },
    {
      label: 'sari-cosmetics',
      name: 'Roni Kosasih',
      brand: 'Sari Cosmetics',
      testimonial: 'Dengan munculnya bisnis online terutama di masa pandemi yang berkepanjangan ini, penting bagi toko offline untuk memiliki platform online agar dapat menjangkau orang lebih banyak Dengan AturToko, bisnis kami dapat mencapai hal ini dengan sangat mudah dan tanpa kerumitan.'
    },
    {
      label: 'brother-boots',
      name: 'Bapak Agung Andriono',
      brand: 'Brother Boots',
      testimonial: 'Penjualan kami menjadi meningkat di tangan AturToko. Mengelola bisnis online kami dengan sangat profesional.'
    },
    {
      label: 'cangcomak',
      name: 'Ibu Salmi Sufraeni',
      brand: 'Cangcomak',
      testimonial: 'AturToko membuat usaha online kami menjadi meningkat dan juga selalu memberikan yang terbaik melaui perencaan yang matang. Terimakasih AturToko!'
    },
    {
      label: 'neoair',
      name: 'Kiki',
      brand: 'Neo Air Indonesia',
      testimonial: 'Semenjak pandemi, kami harus adaptasi dari berjualan offline ke online. Kami tidak memiliki kapasitas untuk mengelola seluruh platform marketplace, oleh karena itu kami mempercayakan AturToko.id untuk mengaturnya.'
    },
    
  ]
}
