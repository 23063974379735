<app-toast></app-toast>
<div class="container-fluid" style="margin-bottom: 50px;">
  <nav class="navbar navbar-expand-lg navbar-light fixed-top">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="assets/logos/aturtoko.svg" alt="AturToko" width="140" height="45">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav" [ngClass]="{ 'show': isCollapsed }">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/tentang']" routerLinkActive="link-active" (click)="isCollapsed = false">Tentang AturToko</a>
          </li>
          <li class="nav-item" ngbDropdown>
            <a class="nav-link" ngbDropdownToggle>Servis AturToko</a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/omnipos']" [routerLinkActive]="'link-active'" (click)="isCollapsed = false">
                OmniPOS
              </a>
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/buattoko']" [routerLinkActive]="'link-active'" (click)="isCollapsed = false">
                BuatToko
              </a>
              <a ngbDropdownItem class="dropdown-item" [routerLink]="['/aturtokoplus']" [routerLinkActive]="'link-active'" (click)="isCollapsed = false">
                AturToko +
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/harga']" routerLinkActive="link-active" (click)="isCollapsed = false">Daftar Harga</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://blog.aturtoko.id/" target="_blank" rel="noreferrer">Blog</a>
          </li>
        </ul>
        <a href="https://store.aturtoko.id" class="btn btn-md btn-custom px-4 py-2 mr-2">Masuk</a>
        <button class="btn btn-md btn-custom4 px-4 py-2 ml-2" (click)="register();">Daftar</button>
      </div>
    </div>
  </nav>
</div>

<router-outlet></router-outlet>

<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <img src="assets/logos/aturtoko.svg" alt="Logo AturToko" width="150" height="49">
        <p>Metropolitan Tower, Lantai 2 <br> Jl. R.A. Kartini No. 14, RW.4, <br> West
          Cilandak, Cilandak, <br> South Jakarta City, Jakarta <br> 12430</p>
        <p class="pt-2">info@aturtoko.id</p>
        <p class="pt-2">PT Atur Toko Solusi Indonesia</p>
      </div>
      <div class="col-6 col-md-3">
        <div class="h4">Servis</div>
        <ul class="box">
          <li><a [routerLink]="['/omnipos']" routerLinkActive="link-active" >OmniPos</a></li>
          <li><a [routerLink]="['/buattoko']" routerLinkActive="link-active" >BuatToko</a></li>
          <li><a [routerLink]="['/aturtokoplus']" routerLinkActive="link-active" >AturToko+</a></li>
          <!-- <li><a [routerLink]="['/privacy']" routerLinkActive="link-active" >Privacy Policy</a></li> -->
        </ul>
      </div>
      <div class="col-6 col-md-3">
        <div class="h4">AturToko</div>
        <ul class="box">
          <li><a [routerLink]="['/tentang']" routerLinkActive="link-active" >Tentang AturToko</a></li>
          <li><a href="https://wa.me/628118880258?text=Halo AturToko" target="_blank" rel="noreferrer">Kontak AturToko</a></li>
          <!-- <li><a [routerLink]="['/karir']" routerLinkActive="link-active" >Karir</a></li> -->
        </ul>
        <div class="column">
          <a href="https://www.facebook.com/profile.php?id=100063537397085" target="_blank" rel="noreferrer"><img class="smicons" src="assets/logos/facebook.webp" alt="Facebook" width="40" height="40"></a>
          <a href="https://www.instagram.com/aturtoko.id/" target="_blank" rel="noreferrer"><img class="smicons" src="assets/logos/instagram.webp" alt="Instagram" width="40" height="40"></a>
          <a href="https://www.linkedin.com/company/aturtoko" target="_blank" rel="noreferrer"><img class="smicons" src="assets/logos/linkedin.webp" alt="LinkedIn" width="40" height="40"></a>
        </div>
      </div>


    </div>
  </div>
</footer>
