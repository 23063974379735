import { Component, OnInit } from '@angular/core';
import { SeoService } from '../seo.service';
import { clients } from './clients';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  testimonials: any;

  rt: any;
  short: any;

  slides = [
    { img: 'assets/partners/tokopedia.webp', label: 'Tokopedia' },
    { img: 'assets/partners/shopee.webp', label: 'Shopee' },
    { img: 'assets/partners/blibli.webp', label: 'Blibli' },
    { img: 'assets/partners/lazada.webp', label: 'Lazada' },
    { img: 'assets/partners/woocommerce.webp', label: 'WooCommerce' },
    { img: 'assets/partners/midtrans.webp', label: 'Midtrans' },
    { img: 'assets/partners/biteship.webp', label: 'Biteship' },
  ];

  liputan = [
    { img: 'assets/coverage/1.webp', url: 'https://www.beritasatu.com/whisnu-bagus-prasetyo/ekonomi/707429/startup4industry-pacu-perusahaan-rintisan-hadirkan-solusi-teknologi-pada-ikm' },
    { img: 'assets/coverage/2.webp', url: 'https://www.antaranews.com/berita/1883580/menperin-harap-program-startup4industry-hasilkan-solusi-teknologi-baru' },
    { img: 'assets/coverage/3.webp', url: 'https://foto.bisnis.com/view/20201208/1328261/penyerahan-penghargaan-kepada-lima-startup-terbaik-dalam-ajang-startup4industry' },
    { img: 'assets/coverage/4.webp', url: 'https://portalbandungtimur.pikiran-rakyat.com/ekonomi/pr-941077836/startup4industry-2020-ciptakan-ekosistem-solusi-teknologi-kala-pandemi' },
    { img: 'assets/coverage/5.webp', url: 'https://finance.detik.com/foto-bisnis/d-5287369/kemenperin-gelar-kompetisi-startup4industry-2020' },
    { img: 'assets/coverage/6.webp', url: 'https://www.liputan6.com/on-off/read/4429745/foto-dorong-teknologi-dalam-negeri-kemenperin-beri-penghargaan-5-startup-terbaik' },
    { img: 'assets/coverage/7.webp', url: 'https://economy.okezone.com/read/2020/12/08/320/2323977/ri-butuh-teknologi-lokal-tunjang-ekonomi-digital' },
    { img: 'assets/coverage/8.webp', url: 'https://ekbis.sindonews.com/read/261996/34/startup4industry-ciptakan-ekosistem-solusi-teknologi-kala-pandemi-1607490732' },
    { img: 'assets/coverage/9.webp', url: 'https://pressrelease.kontan.co.id/release/startup4industry-2020-ciptakan-ekosistem-solusi-teknologi-kala-pandemi?page=all' },
    { img: 'assets/coverage/10.webp', url: 'https://swa.co.id/swa/trends/economic-issues/ciptakan-ekosistem-teknologi-melalui-startup4industry' },
    { img: 'assets/coverage/11.webp', url: 'https://www.industry.co.id/read/77856/gelar-startup4industry-kemenperin-ajak-startup-berikan-layanan-teknologi-bagi-ikm' },
    { img: 'assets/coverage/12.webp', url: 'https://balinesia.id/read/lima-start-up-sabet-penghargaan-startup-4-industry' },
    { img: 'assets/coverage/13.webp', url: 'https://hallomedan.co/read/kemenperin-pilih-lima-start-up-inovasi-terbaik-dalam-negeri' },
    { img: 'assets/coverage/14.webp', url: 'https://halojatim.com/read/inilah-lima-start-up-terbaik-indonesia-versi-startup-4-industry' },
    { img: 'assets/coverage/15.webp', url: 'https://jogjaaja.com/read/lima-start-up-terbaik-kompetisi-startup-4-industry-raih-rp-20-juta' },
    { img: 'assets/coverage/16.webp', url: 'https://jabarjuara.co/read/kemenperin-pilih-lima-start-up-inovasi-terbaik-dalam-negeri' },
    { img: 'assets/coverage/17.webp', url: 'https://www.harianbrebes.com/2021/06/08/audensi-dengan-wakil-bupati-brebes-pt-atur-toko-gandeng-pelaku-umkm/' },
    { img: 'assets/coverage/18.webp', url: 'https://tatiye.id/konsep-aturtoko-solusi-umkm-go-digital-di-kabgor/' },
  ]
  slideConfig = {
    'autoplay': true,
    'slidesToShow': 3,
    'slidesToScroll': 1,
    'dots': true,
    'centerPadding': '50px',
    'centerMode': true,
    'variableWidth': true,
    'focusOnSelect': true,
    'responsive': [
      {
        'breakpoint': 576,
        'settings': {
          'arrows': false,
          'dots': false,
          'swipe': true,
          'lazyLoad': 'ondemand',
          'autoplaySpeed': 1500,
          'centerMode': true,
          'centerPadding': '40px',
          'slidesToShow': 1
        }
      }
    ]
  };

  campaign: any;

  constructor(
    private seo: SeoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.seo.createLinkForCanonicalURL();
    this.testimonials = clients.clients;
    this.rt = this.testimonials[Math.floor(Math.random() * this.testimonials.length)];
    this.short = this.truncate(this.rt.testimonial, 50, true);
  }

  truncate( str: string, n: number, useWordBoundary: boolean ){
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1);
    return (useWordBoundary 
      ? subString.substr(0, subString.lastIndexOf(" ")) 
      : subString) + '...';
  };

  slickInit(e: any) {
  }

  breakpoint(e: any) {
  }

  afterChange(e: any) {
  }

  beforeChange(e: any) {
  }

  toTestimonial() {
    this.router.navigate(['/dashboard'], { fragment: 'testimonial' })
  }

}
