export const tnc: any = {
  data: `<div class="container">
  <h1 class="text-center">KETENTUAN PENGGUNAAN</h1>

  <p class="text-justify">PT. Atur Toko Solusi Indonesia ("Kami"), memiliki dan mengoperasikan AturToko. AturToko
    memberikan Anda rangkaian
    lengkap solusi manajemen ritel untuk meningkatkan penjualan dan efisiensi kerja untuk bisnis online dan offline
    Anda
    ("Layanan ") yang dapat Anda akses melalui situs web Kami di <a
      href="https://aturtoko.id/">https://aturtoko.id/</a>
    ("Situs Web"). Halaman ini menetapkan Layanan dan syarat pengguna Situs Web Kami ("Ketentuan"). Ketentuan dalam
    halaman
    ini penting karena menjelaskan aturan yang harus diikuti saat menggunakan Layanan dan Situs Web; dan syarat dan
    ketentuan
    yang berlaku untuk setiap langganan yang Anda lakukan di Layanan dan Situs Web Kami.</p>

  <h2 class="text-center pt-5">HARAP BACA KETENTUAN INI DENGAN SEKSAMA SEBELUM MENGGUNAKAN LAYANAN ATAU SITUS WEB
    KAMI.
  </h2>

  <p class="text-justify">Saat Kami menggunakan "Anda" atau "milik Anda" dalam Ketentuan ini, Kami merujuk pada siapa
    pun
    yang mengakses atau
    menggunakan Layanan dan Situs Web Kami. Dengan mengakses atau menggunakan Layanan dan Situs Web Kami serta fitur
    apa
    pun (termasuk OmniPos, BuatToko, dan AturToko+) dan konten (termasuk Harga dan FAQ) yang tersedia melalui Situs
    Web,
    Anda menyatakan bahwa Anda telah membaca Ketentuan ini dan menunjukkan persetujuan Anda untuk terikat oleh
    Ketentuan
    ini dan dokumen yang dirujuk di dalamnya. Jika terjadi konflik atau ketidaksesuaian antara Ketentuan dan Harga
    atau
    FAQ, Ketentuan ini yang akan berlaku. </p>

  <p class="text-center">Jika Anda tidak menyetujui bagian mana pun dari Ketentuan ini, Anda harus segera<br> berhenti
    menggunakan Layanan dan Situs Web.
  </p>

  <p class="text-center">
    Jika Anda memiliki pertanyaan, komentar, keluhan, atau klaim sehubungan dengan<br> Layanan dan Situs Web Kami,
    Anda
    dapat menghubungi Kami di:
  </p>

  <p class="text-center"> Email: info@aturtoko.id<br>
    Alamat: Metropolitan Tower, Lantai 2 Jl. R.A. Kartini No. 14, RW.4, Cilandak Barat,<br> Cilandak, Kota Jakarta
    Selatan,
    Jakarta 12430
  </p>
</div>

<div class="container pt-5">
  <ol>
    <li>MENGGUNAKAN LAYANAN DAN SITUS WEB
      <ol class="subtitle-s">
        <li>Pendaftaran
          <ol class="content">
            <li>Anda harus berusia minimal 21 tahun atau lebih untuk menggunakan Layanan. Jika Anda berusia di bawah
              21
              tahun, Anda menyatakan dan menjamin kepada Kami bahwa Anda telah memperoleh persetujuan dari orang tua
              atau wali sah Anda untuk menerima dan mematuhi Ketentuan ini atas nama Anda dan bertanggung jawab atas
              tindakan Anda, dan segala biaya yang terkait dengan penggunaan Anda atas yang Layanan dan Situs Web.
            </li>
            <li>Untuk mengakses dan menggunakan Layanan dan Situs Web Kami, Anda harus mendaftar dan membuat akun
              dengan
              memberikan alamat email Anda yang valid, nomor ponsel yang valid, dan informasi lain yang ditunjukkan
              sebagaimana diperlukan. Sebagai bagian dari proses aktivasi akun, Anda mungkin diminta untuk
              memverifikasi
              alamat email dan/atau nomor ponsel Anda.</li>
            <li>Orang yang mendaftar atau membuka akun akan dianggap sebagai pemilik akun dan pihak yang
              menandatangani
              kontrak untuk tujuan Ketentuan ini dan akan menjadi orang yang berwenang untuk menggunakan akun
              sehubungan
              dengan Layanan. Untuk menghindari keraguan, "Anda" dan "milik Anda" dalam Ketentuan ini merujuk pada
              pemilik akun.</li>
            <li>Jika Anda membuka atau mengoperasikan akun atas nama perusahaan Anda atau pihak ketiga lainnya,
              perusahaan Anda atau pihak ketiga tersebut akan menjadi pemilik akun dan Anda menyatakan dan menjamin
              bahwa Anda memiliki kewenangan untuk bertindak atas nama perusahaan tersebut atau pihak ketiga dan
              mengikat perusahaan atau pihak ketiga tersebut dengan Ketentuan ini. </li>
            <li>Anda menjamin bahwa semua keterangan dan informasi yang diberikan dalam pembuatan akun Anda adalah
              lengkap, benar dan akurat. Anda harus memastikan bahwa keterangan dan informasi pribadi tersebut terus
              diperbarui di profil pengguna.</li>
            <li>Anda sepenuhnya bertanggung jawab atas aktivitas apapun yang terjadi pada akun Anda dan bertanggung
              jawab untuk menjaga keamanan dan kerahasiaan kata sandi Anda ke akun Anda. Anda harus segera memberi
              tahu
              Kami jika Anda mengetahui atau memiliki alasan untuk mencurigai bahwa kerahasiaan kata sandi Anda telah
              dibobol atau jika telah terjadi penggunaan yang tidak sah atas akun Anda atau pelanggaran keamanan
              terkait
              akun lainnya yang diketahui.</li>
            <li>Kami tidak akan bertanggung jawab atas kerugian atau kerusakan yang terjadi sebagai akibat dari
              rincian
              akun Anda yang dibagikan oleh Anda atau disebabkan oleh tindakan yang dilarang oleh pihak ketiga mana
              pun.
            </li>
            <li>Jika terjadi perselisihan mengenai kepemilikan akun, Kami dapat memutuskan layanan untuk sementara
              waktu
              hingga kesepakatan ditentukan antara para pihak yang berselisih. Kami berhak untuk menentukan dan/atau
              memberikan kepemilikan akun berdasarkan penilaian Kami yang wajar, terlepas apakah penyelidikan
              independen
              telah dilakukan oleh Kami atau tidak. Kami berhak meminta dokumentasi apa pun untuk menentukan atau
              mengkonfirmasi kepemilikan akun. Namun, jika Kami tidak dapat membuat keputusan tersebut (yang Kami
              perkirakan berdasarkan kebijakan Kami sendiri), Kami berhak untuk tidak melakukannya, tanpa kewajiban
              kepada Anda atau pihak lain.</li>
          </ol>
        </li>
        <li>Hak Kami
          <ol class="content">
            <li>Kami berhak, tanpa diperlukan pemberitahuan atau persetujuan Anda, untuk:
              <ol class="alphabet">
                <li>Membatasi akses ke, memutuskan layanan atau menghapus akun Anda dengan alasan apapun dan kapanpun;
                </li>
                <li>Melaporkan aktivitas apa pun yang Kami curigai melanggar hukum yang berlaku kepada pihak berwenang
                  yang sesuai dan bekerja sama dengan pihak berwenang tersebut;</li>
                <li>Dalam setiap waktu meminta informasi apapun dari Anda sehubungan dengan penggunaan dan/atau akses
                  Anda ke Layanan dan Situs Web;</li>
                <li>Memberikan Layanan Kami kepada pesaing Anda dan tidak menjanjikan eksklusivitas di segmen pasar
                  tertentu. Anda selanjutnya mengetahui dan menyetujui bahwa karyawan dan kontraktor Kami juga dapat
                  menjadi pelanggan Kami dan bahwa mereka dapat bersaing dengan Anda, meskipun mereka tidak
                  menggunakan
                  informasi rahasia Anda untuk melakukan hal tersebut;</li>
                <li>Mengoptimalkan dan menyesuaikan Layanan dan/atau Situs Web, antarmuka, aturan penggunaan dan
                  lainnya
                  milik Kami dari waktu ke waktu, dan menyesuaikan ruang lingkup dan jenis spesifik dari
                  produk-produk;
                </li>
                <li>Menghapus Konten Pengguna yang, berdasarkan pendapat kamu, melanggar Ketentuan ini atau peraturan
                  perundangan yang berlaku;</li>
                <li>Menolak aplikasi Anda untuk sebuah akun, membatalkan penerbitan akun yang ada, membatalkan
                  langganan
                  atau menghapus akun yang ada dengan alasan apa pun, atas kebijakan Kami sendiri;</li>
                <li>Memblokir, membatasi, menonaktifkan, menangguhkan, atau menghentikan akses Anda ke semua atau
                  sebagian Layanan dan Situs Web kapan saja berdasarkan kebijakan Kami dalam hal:
                  <ol class="roman">
                    <li>Anda telah melanggar atau tidak mematuhi ketentuan apa pun dari Ketentuan ini atau peraturan
                      perundang-undangan yang berlaku;</li>
                    <li>Anda lebih lanjut mendaftarkan dan masuk ke dalam Layanan dan Situs Web Kami secara langsung
                      ataupun tidak langsung atau menggunakan nama orang atau entitas lain setelah akses anda telah
                      diblokir, ditangguhkan atau dihentikan oleh Kami;</li>
                    <li>Anda tidak meningkatkan langganan layanan anda sesuai Pasal 1.3.3 Ketentuan ini; dan/atau</li>
                    <li>Anda tidak melakukan pembayaran sebelum berakhirnya masa berlangganan saat ini sesuai Pasal
                      1.3.4 Ketentuan ini.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>Setelah pemblokiran, pembatasan, penonaktifan, penangguhan, atau penghentian akses Anda ke semua atau
              sebagian Layanan dan Situs Web menjadi efektif, Anda bertanggung jawab penuh atas semua data dan
              informasi
              yang terdapat dalam akun Anda, termasuk untuk memigrasikan atau meneruskan yang diperlukan informasi
              atau
              data. Kami tidak berkewajiban untuk menyimpan informasi atau data apa pun di atau terkait dengan akun
              untuk Anda, atau meneruskan atau memigrasikan informasi atau data apa pun (termasuk namun tidak terbatas
              pada pesan yang belum dibaca atau dikirim) kepada Anda atau pengguna atau pihak ketiga pihak, dan Kami
              tidak akan bertanggung jawab atas segala kerusakan atau kerugian yang ditimbulkan oleh Anda, pengguna,
              atau pihak ketiga karena hal-hal tersebut di atas.</li>
            <li>Setelah penghentian akses Anda ke semua atau sebagian dari Layanan dan/atau Situs Web, Anda dengan ini
              memberi Kami hak untuk menyimpan dan/atau menghapus informasi atau data apa pun di atau terkait dengan
              akun Anda tanpa kewajiban apa pun kepada Anda.</li>
          </ol>
        </li>
        <li>Biaya dan Pembayaran
          <ol class="content">
            <li>Biaya berlangganan untuk berlangganan Layanan Kami tersedia di Halaman Harga Kami. Semua biaya
              berlangganan dibayar di muka, terlepas dari paket berlangganan yang dipilih. Setelah pembayaran biaya
              berlangganan, Anda akan menerima tanda terima pembayaran. Metode pembayaran yang tersedia serta harga
              setiap paket langganan tersedia di halaman Harga Situs Web.</li>
            <li>Setelah berlangganan Layanan Kami, Anda juga dapat meningkatkan paket langganan Anda. Biaya langganan
              untuk meningkatkan paket langganan Anda tersedia di Halaman Harga Kami.</li>
            <li>Jika selama periode layanan berlangganan, jumlah pesanan yang Anda terima (baik yang diproses atau
              dibatalkan) dalam satu bulan di Layanan mencapai volume pesanan bulanan maksimum yang ditentukan oleh
              jenis layanan, Anda harus meningkatkan jenis layanan Anda. Jika Anda tidak meningkatkan layanan Anda
              dalam
              waktu 7 hari kalender sejak tanggal Anda mencapai volume pesanan bulanan maksimum, Kami berhak untuk
              mencegah, membatasi, menurunkan versi, menonaktifkan, menangguhkan, atau menghentikan akses Anda ke
              semua
              atau sebagian layanan di Situs Web dan/atau Layanan, dan Kami tidak akan bertanggung jawab atas kerugian
              Anda. Biaya berlangganan untuk meningkatkan paket langganan Anda dapat diperoleh di halaman harga Kami.
              Anda dapat memilih antara tersebut:
              <ol class="alphabet">
                <li>Metode Top-Up. Anda harus membayar nilai top-up yang mana sejumlah selisih harga jenis layanan
                  (sebagaimana tercantum dalam metode pengembalian uang atau <em>top-up</em> untuk keperluan
                  peningkatan
                  halaman
                  harga Kami) yang ditingkatkan dengan harga jenis layanan asli yang tidak digunakan (harga yang tidak
                  digunakan didasarkan pada rasio jumlah hari yang digunakan / 365 Hitung pengurangan).</li>
                <li>Metode Pengembalian Dana. Anda harus membayar penuh harga layanan yang ditingkatkan dan setelah
                  menerima pembayaran Anda, Kami akan mengembalikan harga jenis layanan asli yang tidak digunakan.
                  Seluruh pajak yang telah Anda bayarkan sehubungan dengan Layanan Kami tidak akan dikembalikan.</li>
              </ol>
            </li>
            <li>Kami dapat memberitahukan kepada Anda setidaknya melalui email 7 hari kalender sebelum berakhirnya
              periode berlangganan yang berlaku untuk mengingatkan Anda tentang masa berlangganan yang berlaku. Jika
              Anda gagal melakukan pembayaran sebelum akhir periode langganan tersebut, Kami dapat, dalam setiap saat
              berdasarkan kebijaksanaan Kami, memblokir, membatasi, menurunkan, menonaktifkan, menangguhkan atau
              menghentikan akses Anda ke semua atau sebagian Layanan dan Situs Web tanpa pemberitahuan sebelumnya atau
              kewajiban terhadap Anda. Setelah itu, kecuali Anda telah menyelesaikan pembayaran untuk perpanjangan
              langganan Anda, Kami menyangkal dan tidak membuat pernyataan atau jaminan sehubungan dengan, dan dalam
              hal
              apapun Kami tidak memiliki kewajiban apa pun yang timbul dari atau sehubungan dengan kerugian,
              kewajiban,
              kerusakan, atau pengeluaran dalam bentuk apapun dari tindakan yang telah Kami lakukan terkait dengan
              akses
              Anda ke Layanan dan Situs Web Kami.</li>
            <li>Jika Anda menghapus akun Anda atau membatalkan langganan Anda ke Layanan Kami, Kami tidak akan
              memberikan pengembalian uang atau rabat untuk biaya langganan Anda, baik secara pro-rata atau
              sebaliknya.
            </li>
            <li>Biaya langganan tidak termasuk pajak, dan Anda akan bertanggung jawab untuk pembayaran semua pajak
              yang
              berlaku terkait dengan penggunaan Anda atas Layanan atau pembayaran apa pun yang Anda lakukan.</li>
          </ol>
        </li>
      </ol>
    </li>

    <li class="pt-4">TINDAKAN YANG DILARANG
      <ol class="subtitle-t">
        <li>Anda setuju untuk menggunakan Layanan dan Situs Web Kami sesuai dengan Ketentuan ini dan untuk
          tujuan yang sah dan tepat. Selanjutnya, Anda setuju untuk tidak:
          <ol class="content">
            <li>Menyatakan atau mencoba untuk menyamarkan Layanan dan Situs Web Kami sebagai produk orang lain selain
              Kami, termasuk menghapus, mengubah, atau mengganti pemberitahuan tentang kepemilikan, merek dagang, nama
              bisnis, logo, atau sebutan asal lainnya;</li>
            <li>Mengganggu atau mengacaukan atau menghancurkan atau mencoba untuk mengganggu Layanan dan Situs Web
              Kami,
              atau server atau jaringan yang terhubung ke Layanan dan Situs Web Kami, termasuk menjalankan atau
              mengaktifkan proses di Layanan dan Situs Web Kami yang mengganggu layanan yang benar atau menempatkan
              beban yang tidak wajar pada Layanan dan infrastruktur Situs Web Kami;</li>
            <li>Melanggar keamanan jaringan apapun, termasuk memecahkan kata sandi atau kode enkripsi, mentransfer
              atau
              menyimpan materi ilegal, atau mendapatkan kata sandi, akun, atau informasi pribadi dari pengguna lain
              Layanan dan Situs Web Kami;</li>
            <li>Memperoleh atau mencoba untuk mendapatkan akses tidak sah, dengan cara apa pun, ke salah satu sistem
              Kami dan sistem yang dimiliki atau dioperasikan oleh Kami;</li>
            <li>Membongkar, merekayasa balik, mengkompilasi, atau memodifikasi perangkat lunak atau aplikasi apa pun
              yang terdapat di dalam atau tersedia di Situs Web Kami secara keseluruhan atau sebagian, atau mencoba
              untuk mendapatkan atau mengakses kode sumber Situs Web Kami;</li>
            <li>Menggunakan perangkat lunak atau proses manual atau otomatis untuk mengekstrak, mengekspor, atau
              mengambil <em>(scrape)</em> materi apa pun yang ditampilkan di Situs Web Kami, termasuk teks, grafik,
              foto,
              gambar,
              ilustrasi, audio, video, data, peringkat, ulasan, dan informasi lainnya ("Konten”). Perangkat lunak atau
              proses manual atau otomatis seperti itu termasuk pencatatan data <em>(datamining)</em>, pengikisan
              <em>(scraping),
                spidering,</em> pengindeksan, penyimpanan, atau pemuatan ulang Konten di luar Situs Web Kami;</li>
            <li>Menggunakan perangkat lunak atau proses manual atau otomatis untuk membuat karya turunan berdasarkan
              Konten di Layanan dan Situs Web Kami;</li>
            <li>Menyalahgunakan, melecehkan, mengancam, meniru atau mengintimidasi siapa pun;</li>
            <li>Melakukan perbuatan penipuan, palsu atau menyesatkan;</li>
            <li>Mengirimkan atau mentransmisikan, atau menyebabkan dikirimnya atau ditransmisikannya materi apa pun
              sebagaimana disebutkan dalam klausul 5.2 di bawah;</li>
            <li>Melakukan tindakan yang mengacaukan atau mencoba untuk mengacaukan keamanan, termasuk namun tidak
              terbatas mengirimkan atau mentransmisikan, atau menyebabkan dikirimnya atau ditransmisikannya, konten
              berbahaya termasuk <em>malware, Trojan horse,</em> atau virus atau kode komputer, file skrip, dan
              program
              lainnya,
              secara ilegal memasuki server Kami, dengan maksud tidak baik memindai server Kami, mengumpulkan data
              Kami
              tanpa otorisasi atau mengganggu akses pengguna ke Layanan dan Situs Web Kami;</li>
            <li>Mengirimkan atau mentransmisikan, atau menyebabkan dikirimnya atau ditransmisikannya, surat massal
              yang
              tidak diminta atau bentuk spam lainnya, termasuk surat sampah atau surat berantai;</li>
            <li>Melanggar hak pihak ketiga mana pun, termasuk hak kekayaan intelektual atau hak kepemilikan;</li>
            <li>Melakukan tindakan apapun yang, berdasarkan diskresi Kami, membatasi atau menghalangi orang atau
              entitas
              lain untuk menggunakan atau menikmati Layanan dan Situs Web Kami; dan</li>
            <li>Melanggar, atau menyebabkan Kami melanggar, hukum, undang-undang, tata cara, atau regulasi apa pun
              yang
              berlaku.</li>
          </ol>
        </li>
        <li>Sehubungan dengan Situs Web, Kami berhak, atas kebijakan Kami (termasuk untuk pelanggaran
          klausul 2.1 di atas), untuk:
          <ol class="content">
            <li>Menghapus konten yang melanggar standar Kami;</li>
            <li>Menangguhkan atau menghentikan akun atau akses Anda ke Situs Web;</li>
            <li>Memberitahukan otoritas terkait; dan</li>
            <li>Mengambil tindakan lain yang Kami anggap pantas atau sebagaimana disarankan.</li>
          </ol>
        </li>
        <li>Jika Anda mengakses Layanan dan Situs Web Kami melalui akun terdaftar, Anda mengakui dan setuju
          bahwa Anda bertanggung jawab untuk menjaga kerahasiaan nama pengguna dan kata sandi akun Anda, Anda akan
          membatasi akses ke komputer Anda, dan Anda setuju untuk menerima tanggung jawab atas semua aktivitas yang
          terjadi pada akun Anda.</li>
      </ol>
    </li>

    <li class="pt-4">DATA PRIBADI ANDA
      <ol class="subtitle-t">
        <li>Kebijakan Privasi Kami juga mengatur penggunaan Anda atas Layanan dan Situs Web Kami. Kebijakan
          Privasi menjelaskan data pribadi apa yang Kami kumpulkan dari Anda, bagaimana dan mengapa Kami mengumpulkan,
          menggunakan, dan mengungkapkan informasi tersebut, hak-hak Anda terkait dengan data pribadi Anda dan
          bagaimana
          menghubungi Kami jika Anda memiliki pertanyaan tentang penggunaan data pribadi Anda.</li>
      </ol>
    </li>

    <li class="pt-4">KEPEMILIKAN, PENGGUNAAAN DAN HAK KEKAYAAN INTELEKTUAL
      <ol class="subtitle-t">
        <li>Semua hak, termasuk hak kekayaan intelektual, di dalam dan terhadap Layanan dan Situs Web Kami,
          termasuk namun tidak terbatas pada desain, teks, grafik, aplikasi, perangkat lunak, kode sumber yang
          mendasarinya, dimiliki oleh Kami atau pemberi lisensi Kami (sebagaimana berlaku). Hak kekayaan intelektual
          berarti hak seperti hak cipta, merek dagang, nama domain, hak desain, hak basis data, paten, dan semua hak
          kekayaan intelektual lainnya dalam bentuk apapun baik terdaftar atau tidak terdaftar.</li>
        <li>Masing-masing dari Kami dan pemberi lisensi Kami memiliki semua hak atas kekayaan intelektual
          apa pun sehubungan dengan Ketentuan ini. Ini berarti, misalnya, bahwa Kami dan mereka tetap menjadi
          pemiliknya
          dan bebas menggunakannya sesuai keinginan Kami.</li>
        <li>Tidak ada dalam Ketentuan ini yang memberi Anda hak hukum dalam Layanan dan Situs Web Kami
          selain yang diperlukan untuk memungkinkan Anda mengakses Layanan dan Situs Web. Anda setuju untuk tidak
          menyesuaikan atau mencoba mengakali atau menghapus pemberitahuan apa pun yang terdapat di Layanan dan Situs
          Web Kami (termasuk pemberitahuan kekayaan intelektual) dan khususnya dalam hak digital atau teknologi
          keamanan
          lainnya yang terdapat atau terkandung dalam Layanan dan Situs Web Kami.</li>
        <li>Anda dengan ini memberi Kami lisensi dan persetujuan yang berlaku di seluruh dunia, bebas
          royalti, dapat dipublikasikan, dapat dialihkan, dan abadi untuk menggunakan nama, merek, merek dagang,
          dan/atau logo Anda untuk tujuan promosi dan pemasaran Layanan Kami, termasuk namun tidak terbatas pada
          menampilkan nama, merek, merek dagang, dan/atau logo di Situs Web Kami.</li>
        <li>Tidak ada hal yang terkandung di Layanan atau Situs Web yang dapat ditafsirkan sebagai
          pemberian lisensi atau hak untuk menggunakan merek dagang apa pun tanpa persetujuan tertulis sebelumnya dari
          pemilik merek dagang.</li>
        <li>Dalam mengakses Layanan dan Situs Web Kami, Anda setuju bahwa setiap pengunduhan konten adalah
          untuk referensi pribadi dan non-komersial saja. Tidak ada bagian dari Layanan dan Situs Web Kami yang dapat
          direproduksi atau ditransmisikan dalam bentuk apa pun atau dengan cara apa pun, elektronik, mekanis,
          fotokopi,
          rekaman, atau lainnya, tanpa persetujuan tertulis sebelumnya dari Kami.</li>
      </ol>
    </li>

    <li class="pt-4">ULASAN DAN KIRIMAN PENGGUNA
      <ol class="subtitle-t">
        <li>Sejauh Layanan dan Situs Web Kami memungkinkan Anda untuk memposting, mengunggah, mengirimkan,
          atau menyediakan Konten apa pun ("Konten Pengguna"), Anda setuju bahwa:
          <ol class="content">
            <li>Anda sepenuhnya bertanggung jawab atas Konten Pengguna yang Anda unggah dan Anda menyatakan dan setuju
              bahwa Anda tidak akan membagikan apa pun yang akan melanggar hak pihak ketiga mana pun, termasuk hak
              kekayaan intelektual atau hak kepemilikan;</li>
            <li>Meskipun semua hak kekayaan intelektual yang ada dalam Konten Pengguna akan menjadi milik Anda atau
              pemberi lisensi Anda dan Anda selalu bebas untuk membagikan Konten Pengguna Anda dengan siapa pun,
              dengan
              ini Anda memberi Kami dan afiliasi Kami hak yang bersifat non-eksklusif, abadi (terus berlaku setelah
              pengakhiran kontrak Kami dengan Anda), bebas royalti, berlaku di seluruh dunia, dapat dialihkan dan
              disublisensikan untuk menampilkan, menggunakan, mereproduksi baik secara elektronik atau sebaliknya,
              menampilkan secara publik, mendistribusikan, memodifikasi, mengadaptasi, menerbitkan, menerjemahkan, dan
              membuat hasil turunan dari setiap dan semua Konten Pengguna tersebut (sesuai dengan Kebijakan Privasi
              Kami), termasuk namun tidak terbatas pada tujuan periklanan dan pemasaran Layanan dan Situs Web Kami;
            </li>
            <li>Kami tidak berkewajiban untuk menyimpan, menahan, menerbitkan, atau menyediakan Konten Pengguna apa
              pun
              yang Anda unggah dan bahwa Anda akan bertanggung jawab untuk membuat cadangan Konten Pengguna apapun
              jika
              perlu.</li>
          </ol>
        </li>
        <li>Anda tidak boleh mengunggah, memposting atau mengirimkan pada atau melalui Layanan dan Situs
          Web Konten Pengguna apa pun yang:
          <ol class="content">
            <li>Melanggar hak cipta pihak ketiga atau hak kekayaan intelektual lainnya atau hak publisitas atau
              privasi;
            </li>
            <li>Berisi konten pornografi, memfitnah, atau ilegal atau tidak bermoral;</li>
            <li>Mengeksploitasi anak di bawah umur;</li>
            <li>Berisi tindakan melanggar hukum atau kekerasan;</li>
            <li>Berisi kekejaman terhadap hewan atau kekerasan terhadap hewan;</li>
            <li>Mempromosikan skema penipuan atau membuat klaim iklan yang menipu atau persaingan tidak sehat; dan
            </li>
            <li>Melanggar hukum, undang-undang atau peraturan apa pun.</li>
          </ol>
        </li>
        <li>Anda dengan ini setuju untuk mengganti kerugian Kami terhadap kerugian, kewajiban, kerusakan
          atau pengeluaran apapun yang Kami, dan afiliasi Kami, mungkin terima yang secara langsung atau tidak
          langsung
          disebabkan oleh atau disebabkan oleh penggunaan Anda atas Layanan dan Situs Web Kami, untuk mengirim atau
          memposting Konten Pengguna apa pun.</li>
      </ol>
    </li>

    <li class="pt-4">HYPERLINKS DAN SITUS PIHAK KETIGA
      <ol class="subtitle-t">
        <li>Tautan eksternal dapat disediakan di Layanan dan Situs Web Kami untuk kenyamanan Anda, tetapi mereka
          berada di luar kendali Kami dan Kami tidak membuat pernyataan tentang tautan ini. Penggunaan atau
          ketergantungan Anda pada tautan eksternal apa pun dan konten di dalamnya dilakukan dengan risiko Anda
          sendiri. Saat mengunjungi tautan eksternal, Anda harus mengacu pada syarat dan ketentuan penggunaan situs
          web eksternal tersebut.</li>
        <li>Anda tidak akan membuat <em>hypertext</em> apa pun dari situs web manapun yang dikendalikan oleh Anda atau
          sebaliknya ke Layanan dan Situs Web Kami tanpa persetujuan tertulis sebelumnya dari Kami.</li>
      </ol>
    </li>

    <li class="pt-4">PENYANGKALAN DAN PEMBATASAN TANGGUNG JAWAB
      <ol class="subtitle-t">
        <li>Sejauh diizinkan oleh hukum dan peraturan yang berlaku, Kami dan afiliasi Kami bersama dengan
          masing-masing pejabat, direktur, para karyawan, agen, mitra, dan perwakilan Kami tidak akan bertanggung
          jawab atas kerusakan langsung, tidak langsung, insidental, khusus, contoh, konsekuensial, atau lainnya apa
          pun, termasuk namun tidak terbatas pada tanggung jawab untuk:
          <ol class="content">
            <li>Hilangnya pendapatan aktual atau yang diantisipasi (baik langsung maupun tidak langsung);</li>
            <li>Hilangnya keuntungan aktual atau yang diantisipasi (baik langsung maupun tidak langsung);</li>
            <li>Hilangnya kontrak atau bisnis atau niat baik (baik langsung maupun tidak langsung); atau</li>
            <li>Kehilangan data, yang timbul karena alasan apapun juga sehubungan dengan Ketentuan ini, bahkan jika
              salah satu dari Kami dan afiliasi Kami bersama dengan masing-masing pejabat, direktur, karyawan, agen,
              mitra, dan perwakilan Kami masing-masing telah diberitahukan tentang kemungkinan kerugian atau kerusakan
              yang, sedang atau akan terjadi, termasuk kerugian atau kerusakan apa pun akibat (i) akses, penggunaan,
              atau tidak dapat digunakannya Layanan atau Situs Web; (ii) eror, kesalahan, atau ketidakakuratan dari
              atau dalam konten apa pun (termasuk Konten Pengguna) yang tersedia melalui Layanan dan/atau Situs Web
              (iii) akses tidak sah ke atau penggunaan server Kami dan/atau data pribadi dan/atau informasi lainnya
              yang disimpan di sini, (iv) gangguan atau penghentian transmisi ke atau dari Layanan atau Situs Web,
              atau (v) penggunaan Anda atas situs atau layanan pihak ketiga atau penggunaan atau akses ke situs web
              lain yang ditautkan ke Situs Web. Ketentuan ini berlaku terlepas dari apakah kerusakan didasarkan pada
              kontrak, kesalahan (termasuk kelalaian), pelanggaran kewajiban hukum atau sebaliknya, termasuk
              berdasarkan kewajiban ganti rugi sesuai Ketentuan ini.</li>
          </ol>
        </li>
        <li>Kami tidak memiliki hubungan khusus dengan atau kewajiban kepada Anda untuk mengakses dan menggunakan
          Layanan dan Situs Web dan Konten Kami. Kami tidak memiliki kendali atas, dan tidak berkewajiban untuk
          mengambil tindakan apapun terkait:
          <ol class="content">
            <li>Pengguna mana yang dapat mengakses ke Layanan dan Situs Web Kami;</li>
            <li>Konten apa yang Anda akses melalui Layanan dan Situs Web Kami;</li>
            <li>Apa pengaruh Konten Kami terhadap Anda;</li>
            <li>Bagaimana Anda dapat menafsirkan atau menggunakan Konten Kami; dan</li>
            <li>Tindakan apa yang mungkin Anda ambil sebagai akibat dari terpaparnya Konten.</li>
          </ol>
        </li>
        <li>Kami juga tidak bertanggung jawab atas biaya apa pun yang terkait dengan penyedia jaringan Anda, yang
          mungkin membebankan biaya kepada Anda untuk mengakses layanan koneksi mereka untuk mengakses dan menggunakan
          Layanan dan Situs Web Kami. Kami juga tidak bertanggung jawab atas ketersediaan dan kualitas penerimaan
          telekomunikasi Anda saat mengakses atau menggunakan Layanan dan Situs Web Kami.</li>
        <li>Import Data
          <ol class="content">
            <li>Anda memahami bahwa karena pengaturan teknis yang mendasari dan alasan lain, dalam proses mengimpor
              data, data mungkin rusak atau berubah. Karena kerumitan dan ketidakpastian migrasi data, Kami menyangkal
              dan tidak menanggung kewajiban hukum atau Kerugian apa pun, termasuk kewajiban kompensasi, untuk data
              yang tidak dipulihkan atau tidak diperbaiki.</li>
            <li>Anda menjamin bahwa data yang dimigrasikan atau diimpor dari platform pihak ketiga lainnya ke Situs
              Web diperoleh secara sah dan sesuai aturan dan telah secara tegas disahkan oleh pihak terkait dan segala
              risiko yang timbul darinya akan ditanggung oleh Anda sendiri. Jika Kami menderita kerugian sebagai
              akibatnya, Anda harus bertanggung jawab untuk kompensasi. Anda menyatakan dan menjamin bahwa Anda
              memiliki semua kualifikasi yang diperlukan dan otorisasi hukum yang diperlukan untuk melaksanakan
              kewajiban ini, dan bahwa tindakan Anda untuk selalu mematuhi hukum yang berlaku terkait termasuk namun
              tidak terbatas pada undang-undang perlindungan data.</li>
            <li>Karena Kami hanya memberi Anda layanan teknis untuk impor data dan tidak, dengan cara apa pun,
              memverifikasi kebenaran, kelengkapan, atau kesesuaian data yang diimpor, Anda harus memastikan kebenaran
              data yang diimpor. Jika pengimporan data tidak dapat dilakukan karena kesalahan data atau hasil
              pengimporan data tidak sesuai atau alasan lain apa pun yang tidak disebabkan oleh kesalahan atau
              kelalaian Kami semata, Kami tidak bertanggung jawab atas kerugian, kewajiban, kerusakan, atau biaya apa
              pun atas sifat apa pun yang Anda alami karena keadaan seperti itu.</li>
          </ol>
        </li>
        <li>Sejauh diizinkan oleh undang-undang dan peraturan yang berlaku, Kami menyangkal dan tidak membuat
          pernyataan atau jaminan sehubungan dengan, dan dalam hal apapun Kami tidak memiliki tanggung jawab apa pun
          yang timbul dari atau sehubungan dengan:
          <ol class="content">
            <li>Legalitas, kesusilaan, atau kepatutan materi apa pun yang terkandung atau diakses melalui Layanan dan
              Situs Web Kami, termasuk materi di situs web lain yang mungkin Kami arahkan kepada Anda;</li>
            <li>Materi iklan apa pun yang dikirimkan oleh pihak ketiga dan ditampilkan di Layanan dan Situs Web Kami,
              termasuk namun tidak terbatas pada, kesalahan, kelalaian, atau ketidakakuratan; dan</li>
            <li>Setiap informasi yang ditampilkan, dan saran atau rekomendasi yang dibuat tentang Layanan dan Situs
              Web untuk tujuan apa pun.</li>
          </ol>
        </li>
        <li>Layanan dan Situs Web dan semua konten, informasi, materi, dan produk yang tersedia dari Layanan dan Situs
          Web disediakan kepada anda secara ketat berdasarkan "sebagaimana adanya" dan "sebagaimana tersedia". Semua
          ketentuan, pernyataan, dan jaminan, baik tersurat, tersirat, berharga atau lainnya, termasuk, tanpa batasan,
          jaminan tersirat atas dapat diperdagangkan, kesesuaian untuk tujuan tertentu, atau bukan pelanggaran atas
          hak pihak ketiga terhadap informasi, materi, dan produk yang tersedia dari situs web ini disangkakan oleh
          Kami sejauh diizinkan oleh hukum yang berlaku (termasuk afiliasi Kami, direksi, pejabat, karyawan, agen,
          kontraktor, penerus dan penerima pengalihan). Tanpa mengurangi hal tersebut, Kami, pemberi lisensi Kami
          tidak memberikan pernyataan, jaminan atau garansi terhadap keandalan, ketepatan waktu, kualitas, kesesuaian,
          kebenaran, ketersediaan, keakuratan, atau kelengkapan dari situs web atau konten, informasi, materi atau
          produk yang tersedia dari layanan dan situs web. Kami (termasuk afiliasi, direksi, pejabat, karyawan, agen,
          kontraktor, penerus dan penerima pengalihan) tidak menyatakan atau menjamin bahwa penggunaan layanan dan
          situs web akan sesuai waktu, tidak terganggu, atau tidak terdapat eror atau dapat beroperasi dengan
          perangkat keras, perangkat lunak, sistem atau data.</li>
        <li>Layanan Kami, termasuk namun tidak terbatas pada OmniPos, BuatToko, danAturToko+, yang tersedia di Situs
          web bergantung pada layanan Antarmuka Pemrograman Situs Web (<em>Website Programming Interface</em> atau
          API) dari platform tempat Layanan Kami terhubung dan API dari platform tersebut mungkin tidak menyediakan
          pembaruan secara real-time kepada Layanan Kami. Sejauh diizinkan oleh undang-undang dan peraturan yang
          berlaku, Kami menyangkal dan tidak membuat pernyataan atau jaminan sehubungan dengan, dan dalam hal apapun
          Kami tidak memiliki kewajiban apa pun yang timbul dari atau sehubungan dengan kehilangan, kewajiban,
          kerusakan, atau biaya dalam bentuk apa pun yang disebabkan oleh ketersediaan API dari platform tersebut,
          termasuk hukuman atau tindakan apa pun yang mungkin dikenakan platform kepada Anda.</li>
        <li>Anda setuju bahwa Kami dapat mengubah atau menyela beberapa atau semua layanan jaringan (yang termasuk,
          namun tidak terbatas pada direktori, pesan, berbagi file, server file dan/atau layanan penyimpanan file),
          dan menghapus informasi apa pun yang Anda kirimkan dalam menggunakan layanan jaringan Kami sesuai dengan
          ketentuan Ketentuan ini, tanpa memberi tahu Anda, dan tanpa kewajiban apa pun kepada Anda.</li>
        <li>Kami berhak untuk merombak atau melakukan pemeliharaan pada Layanan, Situs Web atau peralatan terkait yang
          menyediakan Layanan secara berkala atau insidental, tetapi Kami akan memberi tahu Anda terlebih dahulu
          melalui pengumuman situs web atau cara lain . Kami tidak akan bertanggung jawab atas setiap pengeluaran,
          kerugian (termasuk kehilangan keuntungan atau peluang) atau kerusakan yang Anda alami karena gangguan,
          gangguan, atau penangguhan Layanan karena keadaan seperti itu.</li>
        <li>Fitur yang tersedia di Layanan Kami memungkinkan Anda mengunggah dan menyalin produk dari satu pasar ke
          pasar lain. Namun, Anda bertanggung jawab untuk memastikan bahwa produk yang diunggah dan disalin ke
          platform mana pun memenuhi kriteria dan persyaratan yang ditetapkan oleh platform masing-masing. Sejauh
          diizinkan oleh undang-undang dan peraturan yang berlaku, Kami menyangkal dan tidak membuat pernyataan atau
          jaminan sehubungan dengan, dan dalam hal apapun Kami tidak memiliki kewajiban apa pun yang timbul dari atau
          sehubungan dengan kehilangan, kewajiban, kerusakan, atau biaya dalam bentuk apa pun yang disebabkan oleh
          penggunaan fitur ini oleh Anda.</li>
        <li>Kami akan melakukan upaya yang wajar secara komersial untuk memberikan langkah-langkah keamanan
          berdasarkan praktik industri untuk memastikan keamanan dan pengoperasian normal Layanan dan Situs Web.
          Namun, karena kemungkinan virus komputer, kegagalan komunikasi jaringan, pemeliharaan sistem dan
          faktor-faktor lain serta peristiwa force majeure yang mungkin terjadi, Kami tidak memberikan jaminan bahwa
          Layanan yang diberikan tanpa cacat. Oleh karena itu, jika ada cacat, kekurangan, kegagalan atau kesalahan
          dalam Layanan yang Kami berikan, tetapi cacat, kekurangan, kegagalan atau kesalahan tidak dapat dihindari
          karena tindakan pengamanan yang diterapkan berdasarkan praktik industri saat ini atau teknologi yang ada,
          Kami tidak akan bertanggung jawab atas pelanggaran kontrak dalam keadaan seperti itu.</li>
        <li>Kami tidak bertanggung jawab kepada Anda atas pelanggaran Ketentuan ini yang disebabkan oleh peristiwa
          atau keadaan di luar kendali Kami yang wajar termasuk, namun tidak terbatas pada, mogok kerja, penutupan
          atau perselisihan industri lainnya; kerusakan sistem atau akses jaringan; atau banjir, kebakaran, ledakan
          atau kecelakaan. Jika peristiwa atau keadaan seperti itu mengakibatkan keterlambatan atau kegagalan dalam
          penyediaan Layanan Kami, Kami akan memberi Anda perkiraan waktu kapan Anda akan mendapatkan kembali akses ke
          Layanan Kami, tetapi Anda tidak akan memiliki hak untuk mengakhiri Ketentuan ini karena keterlambatan atau
          atau kegagalan tersebut.</li>
      </ol>
    </li>

    <li class="pt-4">GANTI RUGI
      <ol class="subtitle-t">
        <li>Anda setuju untuk membela, mengganti kerugian, dan membebaskan Kami, pejabat, direktur, pemegang saham,
          karyawan, afiliasi, agen, partner dan perwakilan Kami dari dan terhadap setiap dan semua klaim, kerusakan,
          kewajiban, kerugian, kewajiban, biaya (termasuk biaya pengacara) yang timbul dari (i) pelanggaran Anda
          terhadap salah satu Ketentuan ini; (ii) pelanggaran Anda terhadap hak pihak ketiga mana pun, termasuk hak
          cipta atau hak privasi, yang diakibatkan oleh Konten Pengguna Anda dan penggunaan Anda atas Layanan dan
          Situs Web; dan (iii) jenis klaim lainnya yang membahas dugaan kerusakan yang disebabkan oleh Konten Pengguna
          Anda kepada pihak ketiga.</li>
        <li>Selain itu, Kami dapat menggunakan informasi apa pun yang tersedia tentang Anda untuk menghentikan
          pelanggaran persyaratan ini, penggunaan Layanan atau Situs Web yang melanggar hukum atau tidak tepat,
          termasuk memberi tahu pihak ketiga mana pun yang Kami anggap tepat atas pelanggaran atau penggunaan dan/atau
          mengungkapkan data pribadi Anda kepada pihak ketiga tersebut.</li>
      </ol>
    </li>

    <li class="pt-4">PENGAKHIRAN
      <ol class="subtitle-t">
        <li>Ketentuan ini berakhir Ketika salah satu dari kondisi berikut ini terpenuhi:
          <ol class="content">
            <li>Pemberitahuan pengakhiran: Kami dapat mengakhiri Ketentuan ini dengan pemberitahuan tertulis 30 hari
              kalender sebelumnya tanpa bertanggung jawab atas pelanggaran kontrak;</li>
            <li>Hak pengakhiran sepihak: Anda memahami bahwa Kami berhak untuk segera mengakhiri Ketentuan ini dan
              memberikan sanksi kepada Anda jika:
              <ol class="alphabet">
                <li>Berdasarkan kebijakan Kami sendiri, Anda telah melanggar persyaratan, perjanjian, aturan,
                  pemberitahuan dan ketentuan terkait lainnya dari Layanan dan Situs Web yang relevan; dan/atau</li>
                <li>Suatu data atau informasi yang Anda isi atau kirimkan kepada Kami tidak benar atau tidak akurat.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Akibat pengakhiran setelah Ketentuan ini berakhir:
          <ol class="content">
            <li>Kami akan menghentikan akses Anda ke Situs Web dan Layanan tanpa kewajiban apapun kepada Anda;</li>
            <li>Anda bertanggung jawab penuh atas semua data dan informasi yang terdapat dalam akun Anda, termasuk
              untuk memigrasikan atau meneruskan informasi atau data yang diperlukan. Kami tidak berkewajiban untuk
              menyimpan informasi apa pun di atau terkait dengan akun asli untuk Anda, atau meneruskan atau
              memigrasikan informasi apa pun (termasuk namun tidak terbatas pada pesan yang belum dibaca atau dikirim)
              kepada Anda atau pengguna atau pihak ketiga, dan Kami tidak akan bertanggung jawab atas segala kerusakan
              atau kerugian yang ditimbulkan oleh Anda, pengguna, atau pihak ketiga karena hal tersebut di atas;</li>
            <li>Setiap saat setelah pengakhiran menjadi efektif, Anda harus menghapus atau mengembalikan, atas
              permintaan Kami, semua data yang Anda peroleh dari Layanan dan Situs Web Kami, termasuk namun tidak
              terbatas pada data pengguna, Situs Web atau data operasi Layanan dan menyatakan secara tertulis bahwa
              pengembalian atau pemusnahan tersebut telah terjadi;</li>
            <li>Anda akan tetap berkewajiban untuk melakukan semua kewajibannya yang terutang hingga dan termasuk
              tanggal pengakhiran; dan</li>
            <li>Anda dengan ini memberi Kami hak untuk menyimpan dan/atau menghapus informasi atau data apa pun di
              dalam atau terkait dengan akun Anda tanpa kewajiban apapun kepada Anda.</li>
          </ol>
        </li>
      </ol>
    </li>

    <li class="pt-4">LAIN - LAIN
      <ol class="subtitle-t">
        <li>Kami dapat mengubah Ketentuan ini dari waktu ke waktu dengan memberitahukan Ketentuan yang diperbarui di
          Situs web Kami atau di mana pun Layanan Kami tersedia. Dengan terus menggunakan Layanan atau Situs Web Kami
          setelah perubahan berlaku berarti Anda setuju untuk terikat oleh Ketentuan yang direvisi.</li>
        <li>Tidak ada kegagalan atau penundaan oleh Kami atau salah satu penerima kepentingan Kami dalam menjalankan
          hak atau upaya hukum apa pun berdasarkan Ketentuan ini yang dapat dianggap sebagai pengabaian hak tersebut.
          Pengecualian atau penghilangan ketentuan atau istilah apa pun dari Ketentuan ini tidak dapat dianggap
          pengesampingan atas hak atau upaya hukum yang mungkin Kami miliki berdasarkan hukum yang berlaku.</li>
        <li>Pasal 1.2.3, 4.4, 5.1.2, 5.3, 7, 8, 9.2 dari Ketentuan ini dan ketentuan lain dari Ketentuan ini yang
          menurut sifatnya atau oleh keadaan dimaksudkan untuk bertahan, akan tetap berlaku setelah pengakhiran atau
          berakhirnya Ketentuan ini.</li>
        <li>Tidak ada dalam Ketentuan ini yang dianggap atau ditafsirkan untuk menciptakan kemitraan antara Kami dan
          Anda, dan Anda mengakui bahwa Kami adalah pihak independen terhadap Ketentuan ini dan tidak satu pun dari
          kita yang merupakan agen pihak lainnya.</li>
        <li>Seseorang yang bukan pihak dalam Ketentuan ini tidak akan memiliki hak atau kewajiban berdasarkan hukum
          dan peraturan yang berlaku untuk menegakkan ketentuan apa pun berdasarkan Ketentuan ini.</li>
        <li>Ketentuan ini dan dokumen lain yang secara tegas dirujuk dalam Ketentuan ini, sebagaimana dapat diubah
          dari waktu ke waktu, merupakan keseluruhan perjanjian dan pemahaman antara Kami dan Anda sehubungan dengan
          pokok bahasan Ketentuan ini dan menggantikan perjanjian atau pemahaman sebelumnya di antara Kami dan diri
          Anda sendiri dalam kaitannya dengan hal tersebut.</li>
        <li>Tidak ada kegagalan, penundaan, atau kelalaian oleh Kami dalam menjalankan hak, kekuasaan, atau upaya
          hukum yang disediakan oleh hukum atau berdasarkan Ketentuan ini yang akan beroperasi sebagai pengabaian hak,
          kekuasaan, atau upaya hukum, maupun menghalangi atau membatasi pelaksanaannya atau hak atau upaya hukum
          lainnya di masa mendatang. Tidak ada pelaksanaan tunggal atau sebagian oleh Kami atas hak, kekuasaan, atau
          upaya hukum yang disediakan oleh hukum atau berdasarkan Ketentuan ini yang akan mencegah pelaksanaannya atau
          pelaksanaan hak, kekuasaan, atau upaya hukum lainnya di masa mendatang.</li>
        <li>Ketentuan ini tidak dapat dialihkan, ditransfer atau disublisensikan oleh Anda kecuali dengan persetujuan
          tertulis sebelumnya dari Kami. Kami dapat mentransfer, mengalihkan atau mendelegasikan Ketentuan ini dan hak
          dan kewajiban Kami kepada badan hukum terkait, pembeli bisnis atau pihak ketiga lainnya. Kami akan
          memberitahu Anda secara tertulis sebelum ini terjadi dan Kami akan memastikan bahwa pengalihan tersebut
          tidak akan mempengaruhi hak Anda berdasarkan Ketentuan ini. Dengan terus mengakses atau menggunakan Layanan
          dan Situs Web setelah pemberitahuan tersebut, Anda menyetujui pengalihan atau pendelegasian tersebut.</li>
        <li>Jika ada ketentuan dalam Ketentuan ini (atau bagian dari ketentuan apa pun) yang atau menjadi ilegal,
          tidak valid, atau tidak dapat dilaksanakan, ketentuan atau bagian lainnya akan tetap berlaku sepenuhnya dan
          berlaku sejauh yang diizinkan menurut hukum dan peraturan yang berlaku.</li>
        <li>Ketentuan ini akan ditafsirkan sesuai dengan, dan diatur oleh, hukum Republik Indonesia. Setiap masalah
          yang belum terselesaikan atau pertanyaan interpretasi yang timbul sehubungan dengan Ketentuan ini akan
          diselesaikan secara damai.</li>
        <li>Sesuai dengan Undang-Undang Republik Indonesia Nomor 24 Tahun 2009 tentang Bendera, Bahasa, Lambang dan
          Lagu Kebangsaan dan Peraturan Presiden Republik Indonesia Nomor 63 Tahun 2019 tentang Penggunaan Bahasa
          Indonesia, Ketentuan ini disusun di bahasa Indonesia. Jika terdapat ketidaksesuaian teks bahasa Indonesia,
          atau jika terdapat perselisihan tentang arti atau interpretasi dari ketentuan tertentu, teks bahasa
          Indonesia akan dianggap diubah untuk menyesuaikan dengan, dan untuk membuat teks bahasa Indonesia yang
          relevan konsisten.</li>
      </ol>
    </li>
  </ol>
</div>`
}
